export const TOKENT_KEY_NAME = 'dobrobet-token';
export const DEFAULT_CURRENCY = 'RUB';
export const DEFAULT_TIMEZONE = 3;
export const DEFAULT_LANG = 'en';
export const AVAILABLE_LANGS = ['ru', 'uk', 'en', 'kk', 'az', 'uz', 'da', 'es', 'pt'];
export const ONLY_CURRENCY: string | null = null;
export const EXCLUDED_CURRENCY: string[] | null = ['FUN', 'DEMO', 'BUSD', 'TZS', 'KES'];
export const AVAILABLE_THEMES = ['dark-blue'];
export const DEFAULT_THEME = 'dark-blue';
export const COMPANY_NAME = 'Dobro.bet';
export const SUPPORT_LINK = 'https://t.me/BetDobro_Bot?start=bk';
export const SOCIAL_LINK_TG = 'https://t.me/dobro_bk';
export const SOCIAL_LINK_INST = '';
export const SOCIAL_LINK_VK = '';
export const SOCIAL_LINK_FACEBOOK = '';
export const SOCIAL_LINK_TWITTER_X = '';
export const SOCIAL_LINK_TIKTOK = '';
export const LOGO_BOOMAKER_RATINGS = 'https://bookmaker-ratings.ru/review/dobrobet/';
export const ANDROID_APP = 'https://drive.google.com/file/d/1qANZbv0c91PYk8FO6CNqel8o555-WPvL/view';
export const EXCLUDE_CASINO_CATEGORY = ['gameslobby', 'roshambo', 'arcade', 'moon', 'jetx'];
export const PASSWORD_MIN_LENGTH = 8;

export const DEFAULT_LOGOTYPE = 'logo-dobro.svg';
export const MainLogotype = {
  DARK_PURPLE: 'logo-dobro.svg',
  BLUE: 'logo-dobro.svg',
  DARK_BLUE: 'logo-dobro.svg',
  LIGHT_BLUE: 'logo-dobro.svg',
};
export const CouponLogotype = {
  DARK_PURPLE: '/logo-coupon.png',
  BLUE: '/logo-coupon.png',
  DARK_BLUE: '/logo-coupon.png',
  LIGHT_BLUE: '/logo-coupon.png',
};

export const AppFeature = {
  DIGITAIN_GAMES: false,
  BONUS_AND_PROMO: false,
  REGISTRATION_TELEGRAM: false,
  REGISTRATION_PHONE: false,
  KYC_VERIFICATION: false,
  ADAPTIVE_BANNERS: false,
  KES_HOME_PAGE: false,
  KES_PROJECT: false,
  KES_TAXES: false,
  DISABLE_SPORTBOOK: false,
  CASINO_ONLY: false,
};

if (ONLY_CURRENCY && EXCLUDED_CURRENCY) {
  if (EXCLUDED_CURRENCY.includes(ONLY_CURRENCY)) {
    throw new Error('EXCLUDED_CURRENCY contains the ONLY_CURRENCY value');
  }
}

// Просто
